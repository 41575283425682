
import { Mixins, Component, Ref } from "vue-property-decorator";
import tablePagination from "@/mixins/tablePagination";
import ETable from "@/components/ETable.vue";
import { Form } from "element-ui/types";
import loading from "@/mixins/loading";
import dayjs from "dayjs";
import { getDeviceType, getDataPage } from "@/api/tools";

import { cloneDeep } from "lodash";

@Component({
    components: { ETable }
})
export default class logQuery extends Mixins(loading, tablePagination) {
    @Ref("validateForm") formRef: Form;
    rules = {
        deviceName: [
            { required: true, message: "请输入完整的设备ID", trigger: "blur" },
        ],
        deviceType: [
            { required: true, message: "请选择设备类型", trigger: "change" },
        ]
    };
    itemTotal = 0;
    sourceDataList = [];
    tableData = [];
    deviceTypeList = [];
    queryForm = {
        deviceName: "",
        deviceType: "0000",
        times: []
    };

    columns = [
        { label: "时间", formatter: (row) => dayjs(row.time).format("YYYY-MM-DD HH:mm:ss") },
        { label: "设备ID", prop: "devicename" },
        { label: "腾讯云产品ID", prop: "productid" },
        { label: "topic", prop: "topic" },
        { label: "payload", prop: "payload" },
        { label: "command", prop: "command" },
        { label: "解析", prop: "message" }
    ]

    mounted() {
        this.setTimes();
        this.getSourceData();
        this.getDeviceType();
    }

    getDeviceType() {
        getDeviceType().then((res) => {
            this.deviceTypeList = res.data;
        })
    }

    // 设置默认的开始和结束时间
    setTimes() {
        const now = dayjs();
        const startTime = now.subtract(2, "day").startOf("day").format("YYYY-MM-DD HH:mm:ss");
        const endTime = now.endOf("day").format("YYYY-MM-DD HH:mm:ss");
        this.queryForm.times = [startTime, endTime];
    }

    searchData() {
        this.formRef?.validate((valid) => {
            if (valid) {
                this.resetCurrent();
                this.getSourceData();
            } else {
                return false;
            }
        })
    }

    getSourceData() {
        const params = {
            pageNum: this.currentPage,
            pageSize: this.pageSize,
            deviceName: this.queryForm.deviceName,
            deviceType: this.queryForm.deviceType,
            startTime: this.queryForm.times && dayjs(this.queryForm.times[0]).format("YYYY-MM-DD HH:mm:ss"),
            endTime: this.queryForm.times && dayjs(this.queryForm.times[1]).format("YYYY-MM-DD HH:mm:ss"),
        }
        this.showLoading();
        return getDataPage(params).then((res) => {
            this.sourceDataList = res.data;
            this.itemTotal =res.data.length;
            this.getData();
        })
    }
    getData() {
        this.tableData = cloneDeep(this.sourceDataList.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize));
    }
    
}

